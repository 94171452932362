@import url(//hello.myfonts.net/count/3d1a27);
.sui-layout-body:after {
height: 0
}

.sui-result__language, .sui-result__contentType, .sui-result__publication, .clearFilters_button {
    background: none!important;
    border: none;
    padding: 0!important;
    /*optional*/
    /* font-family: arial, sans-serif; */
    /*input has OS specific font-family*/
    /* color: #069; */
    /* text-decoration: underline; */
    cursor: pointer;

    font-weight: 400;
    font-size: 16px;

    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    line-height: 1.5;
    color: #525252
  }

  .sui-footer {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.5;
    color: #525252
  }

  .clearFilters_outer_div {
    margin-left: auto;
    margin-right: 25px;
  }

  .clearFilters_button  {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    line-height: 1.5;
    font-weight: 400;
    color: #4a4b4b;
    font-size: 12px;
    display: inline-block;
    text-decoration: underline;
  }

button:focus {
    outline: none; /* Remove the outline */
}

button.active {
  border-style: none; /* Remove the border */
}

body {
  margin: 0;
}

.sui-layout-header {
  padding: 16px 24px;
}

.sui-layout-header__inner {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}

.sui-layout-header {
  padding-top: 0;
}


.header_info {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
}

.header_img_covid {
  height: 150px;
  float:left;
}

.header_img_syllabus_wrapper {
  height: auto;
  float:right;
  margin-top: auto;
  margin-bottom: auto;
}

.header_img_syllabus {
width: 170px
}

.header_text {
  flex: 1 1 auto;
  margin: auto 40px auto 0px;
  color: black;
}

.header_info {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    line-height: 1.5;
    font-weight: 400;
}

.header_title {
  font-size: 1.5em;
  width: 100%;
  margin: auto;
  font-weight: bold;
}

.header_poweredby {
  font-size: 0.8em;
  text-align: center;
  font-weight: 200;
}

.header_poweredby_syllabus {
  position: relative;
  bottom: 10px;
}


.header_right {
  margin-left: auto;
  display: flex;
  flex-direction:column;
  align-items: flex-end;
  flex-shrink: 0;
}

@media only screen and (max-width: 800px) {
  .header_img_covid {
    height: 80px;
    float:left;
  }

  .header_img_syllabus_wrapper {
    display: none;
  }

  .header_intro {
    display: none;
  }
}


/* newsletter */
.newsletter_container{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* @import must be at top of file, otherwise CSS will not work */

@font-face {
  font-family: "FuturaPT-Medium";
  src: url('/webFonts/FuturaPTMedium/font.woff2') format('woff2'), url('/webFonts/FuturaPTMedium/font.woff') format('woff');
}

.header_text {
  max-width: 850px;
  margin-top: 3px;
  font-family: Arial, Helvetica, sans-serif;
}

.header_title {
    font-family: "FuturaPT-Medium", Arial, Helvetica, sans-serif;
    font-size: 38px;
    font-weight: 10;
}

.header_text a, .header_text a:visited  {
    color:black;
}

.header_intro {
    font-size: 16px;
}

.header_info {
    margin-bottom: 15px;
}

.header_img_syllabus {
    margin-left:12px;
    width: 132px;
}

.header_img_cdr {
    width:78px;
}

.header_img_syllabus_wrapper {
    margin-bottom: 0px;
    margin-top: 10px;
    font-family: Arial, Helvetica, sans-serif;
}

.header_poweredby_syllabus {
    bottom:7px;
}

.header_img_main {
    margin-top: auto;
}

.sui-layout-header__inner {
    padding: 0 24px
}

.sui-layout-header {
    padding-right: 0px;
    padding-left: 0px;
    font-family: Arial, Helvetica, sans-serif;
}

.sui-search-box__text-input, .sui-search-box__text-input:focus {
    border-radius:0;
    border: 3px solid black;
    border-style: dashed;

    padding: 10px;
    padding-left: 15px;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    box-shadow: none;
}

.sui-search-box__text-input::-webkit-input-placeholder {
    color: #7F7F7F;
  }

.sui-search-box__text-input::-ms-input-placeholder {
    color: #7F7F7F;
  }

.sui-search-box__text-input::placeholder {
    color: #7F7F7F;
  }

.sui-search-box__submit, .sui-search-box__submit:hover {
    border-radius: 0;
    color: white;
    background: black;
    width:155px;
    padding: 10px;
    font-size: 16px;
    margin-left:15px;
}

.sui-search-box__submit:hover {
    background: #7F7F7F;
    border: 0px;
    box-shadow: none;
}

.sui-search-box__submit:focus {
    outline: none;
    box-shadow: none;
}

.header_poweredby {
    letter-spacing: 0.2px;
    /* font-size: 0.75em; */
}


.newsletter_wrapper *{
  font-family: "FuturaPT-Medium", Arial, Helvetica, sans-serif !important;
  font-weight: normal !important;
}
.newsletter_wrapper .ql-editor{
  font-size: 20px !important;
}
.newsletter_wrapper a{
  color:black !important;
}

