/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/3d1a27");

@font-face {
  font-family: "FuturaPT-Medium";
  src: url('/webFonts/FuturaPTMedium/font.woff2') format('woff2'), url('/webFonts/FuturaPTMedium/font.woff') format('woff');
}

.header_text {
  max-width: 850px;
  margin-top: 3px;
  font-family: Arial, Helvetica, sans-serif;
}

.header_title {
    font-family: "FuturaPT-Medium", Arial, Helvetica, sans-serif;
    font-size: 38px;
    font-weight: 10;
}

.header_text a, .header_text a:visited  {
    color:black;
}

.header_intro {
    font-size: 16px;
}

.header_info {
    margin-bottom: 15px;
}

.header_img_syllabus {
    margin-left:12px;
    width: 132px;
}

.header_img_cdr {
    width:78px;
}

.header_img_syllabus_wrapper {
    margin-bottom: 0px;
    margin-top: 10px;
    font-family: Arial, Helvetica, sans-serif;
}

.header_poweredby_syllabus {
    bottom:7px;
}

.header_img_main {
    margin-top: auto;
}

.sui-layout-header__inner {
    padding: 0 24px
}

.sui-layout-header {
    padding-right: 0px;
    padding-left: 0px;
    font-family: Arial, Helvetica, sans-serif;
}

.sui-search-box__text-input, .sui-search-box__text-input:focus {
    border-radius:0;
    border: 3px solid black;
    border-style: dashed;

    padding: 10px;
    padding-left: 15px;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    box-shadow: none;
}

.sui-search-box__text-input::placeholder {
    color: #7F7F7F;
  }

.sui-search-box__submit, .sui-search-box__submit:hover {
    border-radius: 0;
    color: white;
    background: black;
    width:155px;
    padding: 10px;
    font-size: 16px;
    margin-left:15px;
}

.sui-search-box__submit:hover {
    background: #7F7F7F;
    border: 0px;
    box-shadow: none;
}

.sui-search-box__submit:focus {
    outline: none;
    box-shadow: none;
}

.header_poweredby {
    letter-spacing: 0.2px;
    /* font-size: 0.75em; */
}


.newsletter_wrapper *{
  font-family: "FuturaPT-Medium", Arial, Helvetica, sans-serif !important;
  font-weight: normal !important;
}
.newsletter_wrapper .ql-editor{
  font-size: 20px !important;
}
.newsletter_wrapper a{
  color:black !important;
}
